import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Dialog, DialogClose, DialogContent } from "@/app/components/ui/dialog"
import { isClient } from "@/app/configs"
import { MobileContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IBannerGame } from "@/app/interfaces"
import { useQueryGetListGameBanner } from "@/app/services/query-fn/game"

import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Autoplay, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const StoreBanner = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [showBannerPopup, setShowBannerPopup] = useState<boolean>(false)

  const hasClosedPopup = useRef(false)

  const GAEventsTracker = useGAEventsTracker("Store_card")
  const token = isClient ? window.localStorage.getItem("token") : null

  const { isMobile } = useContext(MobileContext)
  const { data: listGameBanner } = useQueryGetListGameBanner({
    type: "store"
  })

  useEffect(() => {
    if (
      !showBannerPopup &&
      !!listGameBanner &&
      !!token &&
      !hasClosedPopup.current
    ) {
      const hasPopup = listGameBanner?.some(
        (item: IBannerGame) => !!item.open_popup
      )
      if (hasPopup) {
        setShowBannerPopup(true)
      }
    }
  }, [showBannerPopup, listGameBanner, token, isMobile])

  const convertLink = (url: string) => {
    const link =
      url?.includes(process.env.NEXT_PUBLIC_EVENT_DOMAIN || "") && token
        ? `${url}?token=${token}`
        : url
    return link
  }

  const listPopupBanner = useMemo(() => {
    if (!listGameBanner || !listGameBanner?.length) {
      return []
    }
    return listGameBanner?.filter((item: IBannerGame) => !!item.open_popup)
  }, [listGameBanner])
  const listNotPopup = useMemo(() => {
    if (!listGameBanner || !listGameBanner?.length) {
      return []
    }
    return listGameBanner?.filter((item: IBannerGame) => !item.open_popup)
  }, [listGameBanner])

  const onAdditionalOpenBannerUrl = (banner: IBannerGame) => {
    GAEventsTracker("Store_Banner Click", banner?.click_url)
    setShowBannerPopup(false)
    hasClosedPopup.current = true
    router.push(convertLink(banner?.click_url))
  }

  const onAdditionalOpenBannerStoreUrl = (banner: IBannerGame) => {
    GAEventsTracker("Store_Banner Click", banner?.click_url)
  }

  const onClosePopup = (e: any) => {
    hasClosedPopup.current = true
    setShowBannerPopup(false)
  }
  return (
    <>
      <div className="relative hidden-scroll-bar">
        <div className="relative md:mt-[45px] rounded-[15px]">
          {listNotPopup && listNotPopup?.length > 0 && (
            <Swiper
              modules={[Autoplay, Pagination]}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
            >
              {listNotPopup?.length
                ? listNotPopup.map((item: any, i: number) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="relative">
                          <Link
                            href={convertLink(item?.click_url)}
                            target="_blank"
                            onClick={() => onAdditionalOpenBannerStoreUrl(item)}
                          >
                            <div className="rounded-[15px] overflow-hidden relative">
                              <img
                                src={item?.banner}
                                alt={item.game_id}
                                className="w-[100%] aspect-[3/1] object-cover"
                              />
                            </div>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                  })
                : null}
            </Swiper>
          )}
        </div>
      </div>

      {/* Popup banner */}
      <Dialog open={showBannerPopup} onOpenChange={onClosePopup}>
        <DialogContent className="flex flex-col items-center p-0 bg-transparent border-0 shadow-none dark:bg-transparent focus-visible:outline-none w-fit h-fit max-w-[90vw] max-h-[90vh] overflow-hidden">
          {listPopupBanner && listPopupBanner?.length > 0 && (
            <div className="rounded-[20px] relative">
              <img
                src={
                  isMobile
                    ? listPopupBanner[0]?.banner_mobile
                    : listPopupBanner[0]?.banner_app ||
                      listPopupBanner[0]?.banner
                }
                className={`rounded-[20px]`}
                onClick={() => onAdditionalOpenBannerUrl(listPopupBanner[0])}
              />
              <DialogClose
                className="absolute transition-opacity rounded-sm right-[10px] top-[14px] focus:outline-none disabled:pointer-events-none"
                onClick={onClosePopup}
              >
                <div className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center">
                  <IconClose fill="#fff" />
                </div>
              </DialogClose>
            </div>
          )}
          <p
            className="text-sm text-white underline opacity-100 cursor-pointer Nunito-400 active:opacity-80"
            onClick={onClosePopup}
          >
            {t("common.close_window")}
          </p>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default StoreBanner
