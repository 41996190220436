import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { isClient } from "@/app/configs"
import { jwtDecode } from "jwt-decode"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import UpdateImage from "public/img/store/update-policy.png"
import { FC } from "react"

interface UpdatePhoneModalProps {
  visible: boolean
  setVisible: (param: boolean) => void
  callback?: string
}

export const UpdatePhoneModal: FC<UpdatePhoneModalProps> = ({
  visible,
  setVisible,
  callback
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const accessToken = isClient ? window.localStorage.getItem("token") : null
  const dataFromToken: any = jwtDecode(accessToken || "")

  const onSubmit = () => {
    router.push(
      `https://id.gamota.com/authorize?access_token=${
        dataFromToken?.data?.id
      }&callback=/user/update_phone&redirect=${
        callback ? callback : window.location.href
      }?update=1`
    )
  }

  return (
    <Dialog
      open={visible}
      onOpenChange={() => {
        setVisible(false)
      }}
    >
      <DialogContent className="sm:rounded-[16px] rounded-[16px] max-w-[90vw] md:max-w-[480px] p-[24px] border-0 focus-visible:outline-none dark:bg-bg-02">
        <img src={UpdateImage.src} alt="update" className="mx-auto" />
        <h1 className="text-center Nunito-700 text-[20px] pt-[16px] text-black dark:text-white">
          {t("store.update_phone_title")}
        </h1>
        <p className="text-center px-[12px]">{t("store.update_phone_desc")}</p>
        <div className="flex justify-between mt-[24px]">
          <Button
            variant="none"
            className="text-base bg-bg-button text-neutral-05 Nunito-700 rounded-[16px] dark:text-main-white dark:bg-[#ECECEE1A]"
            autoFocus={false}
            onClick={() => setVisible(false)}
          >
            {t("common.later")}
          </Button>
          <Button
            variant="main"
            className="text-base text-white bg-main-01 Nunito-700 rounded-[16px] dark:bg-[#DAD9FE4D] dark:text-white"
            onClick={onSubmit}
          >
            {t("store.update_phone_now")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
