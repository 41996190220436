import { DataEmpty } from "@/app/components/data-empty"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { Input } from "@/app/components/ui/input"
import { OPEN_MODAL } from "@/app/configs"
import { AppContext } from "@/app/context"
import { removeVietnameseTones } from "@/app/helpers/function"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useQueryGetListTopup } from "@/app/services/query-fn/topup"
import { ITopupGame } from "@interfaces/ITopup"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import ImageLa from "public/img/store/icon_la.png"
import React, { useContext, useEffect, useRef, useState } from "react"
import { UpdatePhoneModal } from "../topup/detail/UpdatePhoneModal"

const HEADER_HEIGHT = 84
const TopupGame: React.FC<{
  game: ITopupGame
  setVisible: (param: boolean) => void
  setCallback: (param: string) => void
}> = ({ game, setVisible, setCallback }) => {
  const router = useRouter()
  const GAEventsTracker = useGAEventsTracker("Store_TopupGame")
  const { userInfo } = useContext(AppContext)
  const link = userInfo?.userid
    ? userInfo?.phone && userInfo?.phone_verified
      ? `/topup/${game?.slug_name}`
      : undefined
    : `/topup/${game?.slug_name}`
  const onOpenTopup = () => {
    console.log(process.env.NEXT_PUBLIC_HOST_URL + `/topup/${game?.slug_name}`)

    if (userInfo?.userid && (!userInfo?.phone || !userInfo?.phone_verified)) {
      setVisible(true)
      setCallback(
        process.env.NEXT_PUBLIC_HOST_URL + `/topup/${game?.slug_name}`
      )
    }
  }

  return (
    <a
      href={link}
      // target={link ? "_blank" : "_parent"}
      // rel={link ? "noreferrer" : ""}
    >
      <div
        className="relative w-full cursor-pointer scale-card"
        onClick={() => {
          GAEventsTracker("Store_Topup_Selectgame", game?.game_name)
          onOpenTopup()
        }}
      >
        <img
          src={game?.icon}
          alt={game?.game_name}
          title={game?.game_name}
          className="w-full object-cover aspect-square rounded-[16px] md:rounded-[24px] mb-[16px] xs:mb-[8px] transition duration-300"
        />
        <p className="text-[16px] Nunito-600 pl-1 xs:pl-0 xs:text-center xs:text-[12px]">
          {game?.game_name}
        </p>
      </div>
    </a>
  )
}

const TopupGameSearch: React.FC<{
  game: ITopupGame
}> = ({ game }) => {
  const router = useRouter()
  const GAEventsTracker = useGAEventsTracker("Store_TopupGame")

  return (
    <Link
      href={{
        pathname: "/topup/[slug_name]",
        query: {
          slug_name: game?.slug_name
        }
      }}
    >
      <div
        className="relative flex items-center gap-2 cursor-pointer"
        onClick={() => {
          GAEventsTracker("Store_Topup_Selectgame", game?.game_name)
        }}
      >
        <img
          src={game?.icon}
          alt={game?.game_name}
          title={game?.game_name}
          className="object-cover aspect-square rounded-[8px] w-[32px] h-[32px]"
        />
        <p className="text-[16px] Nunito-600">{game?.game_name}</p>
      </div>
    </Link>
  )
}

const StoreTopupGame = () => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)
  const { data, isLoading } = useQueryGetListTopup()
  const [value, setValue] = useState("")
  const { userInfo, setOpenModal } = useContext(AppContext)
  const [visible, setVisible] = useState(false)
  const [visibleVerifyPhone, setVisibleVerifyPhone] = useState(false)
  const [callback, setCallback] = useState("")
  const router = useRouter()
  const topupRef = useRef<HTMLDivElement>(null)
  const filterList = !value
    ? data
    : data?.filter((game) => {
        return removeVietnameseTones(game?.game_name?.toLowerCase()).includes(
          removeVietnameseTones(value?.toLowerCase())
        )
      })

  useEffect(() => {
    const preventScroll = () => window.scrollTo(0, 0)

    window.addEventListener("resize", preventScroll)
    return () => {
      window.removeEventListener("resize", preventScroll)
    }
  }, [])

  useEffect(() => {
    if (
      router.query.type === "topup" &&
      data &&
      data?.length > 0 &&
      topupRef &&
      topupRef?.current
    ) {
      const element = topupRef.current
      const offset = HEADER_HEIGHT
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition
        // behavior: "smooth"
      })
    }
  }, [router, data, topupRef])

  return (
    <div className="mt-[24px] md:mt-[32px]" ref={topupRef}>
      <div className="flex md:flex-row flex-col justify-between items-center pb-[16px] md:pb-[12px]">
        <div className="flex items-center w-full">
          <h2 className="text-[20px] Nunito-700 xs:text-[16px] leading-[27px] mr-[24px]">
            {t("store.game_topup")}
          </h2>

          <>
            <img
              src={ImageLa.src}
              className="w-[20px] h-[20px] mr-[8px] xs:ml-auto"
            />
            <p className="text-[14px] Nunito-600 text-main-01">
              {userInfo?.total_la?.toLocaleString()}
            </p>

            <div className="w-[1px] h-[12px] mx-[12px] bg-black/10"></div>
            <Link
              href={
                !userInfo?.id
                  ? "/store/#"
                  : {
                      pathname: "/topup/[slug_name]",
                      query: {
                        slug_name: "napla"
                      }
                    }
              }
              onClick={() => {
                if (!userInfo?.id) {
                  setOpenModal(OPEN_MODAL.LOGIN_MODAL)
                }
              }}
              className="flex items-center gap-[8px] cursor-pointer active:opacity-60"
            >
              <p className="text-[14px] Nunito-600 text-main-01">
                {t("store.la_invoice")}
              </p>
            </Link>
          </>
        </div>

        <div className="hidden md:px-[16px] py-[8px] md:flex gap-[16px] items-center border-b-[1px] border-black/10 dark:border-neutral-05 w-full md:w-[320px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="w-[16px] md:w-[20px]"
          >
            <g clipPath="url(#clip0_11447_46057)">
              <path
                d="M18.3337 18.3333L16.667 16.6666M1.66699 9.58329C1.66699 5.21104 5.2114 1.66663 9.58366 1.66663C13.9559 1.66663 17.5003 5.21104 17.5003 9.58329C17.5003 13.9555 13.9559 17.5 9.58366 17.5C5.2114 17.5 1.66699 13.9555 1.66699 9.58329Z"
                stroke={themeApp === "dark" ? "#FAF8FF" : "#100D28"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11447_46057">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Input
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            placeholder={t("merchandise.search_by_game")}
            className="h-[24px] md:h-[30px] border-0 outline-0 bg-transparent flex-1 p-0"
          />
        </div>
        <button
          className="md:hidden flex px-0 !rounded-0 py-[8px] md:flex gap-[16px] items-center border-b-[1px] bg-transparent active:bg-transparent focus:bg-transparent active:opacity-60 border-black/10 dark:border-neutral-05 w-full"
          onClick={(e) => {
            setVisible(true)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="w-[16px] md:w-[20px]"
          >
            <g clipPath="url(#clip0_11447_46057)">
              <path
                d="M18.3337 18.3333L16.667 16.6666M1.66699 9.58329C1.66699 5.21104 5.2114 1.66663 9.58366 1.66663C13.9559 1.66663 17.5003 5.21104 17.5003 9.58329C17.5003 13.9555 13.9559 17.5 9.58366 17.5C5.2114 17.5 1.66699 13.9555 1.66699 9.58329Z"
                stroke={themeApp === "dark" ? "#FAF8FF" : "#100D28"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11447_46057">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="h-[24px] text-left text-neutral-06 md:h-[30px] border-0 outline-0 bg-transparent flex-1 p-0">
            {value || t("merchandise.search_by_game")}
          </div>
        </button>
      </div>
      <div
        className={`w-full grid sd:grid-cols-5 gap-[24px] xs:gap-[12px] gap-y-[24px] md:gap-y-[24px] md:grid-cols-4 sm:grid-cols-3 grid-cols-3`}
      >
        {filterList?.map((game: ITopupGame, index: number) => {
          return (
            <TopupGame
              game={game}
              key={index}
              setVisible={setVisibleVerifyPhone}
              setCallback={setCallback}
            />
          )
        })}

        {filterList?.length === 0 && !isLoading && (
          <div className="xl:col-span-5 md:col-span-4 sm:col-span-3 col-span-2 my-[40px]">
            <DataEmpty />
          </div>
        )}
      </div>

      <Dialog open={visible} onOpenChange={() => {}}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="overflow-hidden h-[100dvh] max-h-[100dvh] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-0 p-[24px] pt-0 gap-0 flex flex-col"
        >
          <div className="h-[100px] w-full flex flex-col justify-end">
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] Nunito-700" />
              <IconClose
                className="cursor-pointer"
                onClick={() => {
                  setVisible(false)
                  setValue("")
                }}
              />
            </div>

            <div className="px-[16px] py-[8px] flex gap-[16px] items-center border-b-[1px] border-black/10 dark:border-neutral-05 w-full md:w-[320px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_11447_46057)">
                  <path
                    d="M18.3337 18.3333L16.667 16.6666M1.66699 9.58329C1.66699 5.21104 5.2114 1.66663 9.58366 1.66663C13.9559 1.66663 17.5003 5.21104 17.5003 9.58329C17.5003 13.9555 13.9559 17.5 9.58366 17.5C5.2114 17.5 1.66699 13.9555 1.66699 9.58329Z"
                    stroke={themeApp === "dark" ? "#FAF8FF" : "#100D28"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11447_46057">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <Input
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                autoFocus={false}
                placeholder={t("merchandise.search_by_game")}
                className="h-[24px] md:h-[30px] border-0 outline-0 bg-transparent p-0 text-[16px] touch-manipulation flex-1 appearance-none"
              />
            </div>
          </div>

          <div className="flex-1 h-full max-h-[60vh] px-[16px] mt-[30px] space-y-[16px] flex flex-col overflow-auto">
            {filterList?.length === 0 && !isLoading ? null : value ? (
              <p className="text-[16px] Nunito-500 text-neutral-05">
                {t("common.search_result")}
              </p>
            ) : (
              <p className="text-[16px] Nunito-500 text-neutral-05">
                Hot Release
              </p>
            )}
            {filterList
              ?.slice(0, value ? 100 : 5)
              ?.map((game: ITopupGame, index: number) => {
                return <TopupGameSearch game={game} key={index} />
              })}

            {filterList?.length === 0 && !isLoading && (
              <div className="min-h-[300px] flex items-center justify-center">
                <DataEmpty />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {visibleVerifyPhone && callback && (
        <UpdatePhoneModal
          visible={visibleVerifyPhone}
          setVisible={setVisibleVerifyPhone}
          callback={callback}
        />
      )}
    </div>
  )
}

export default StoreTopupGame
